
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.menu-table-container {
    padding: 9px;
    padding-top: 0;
    /* max-width: 600px;
    margin: 0 auto; */
  }
  
  .menu-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .menu-table th {
    padding: 10px;
    font-weight: bold;
    text-align: left;
    font-size: 13px;
    
  }
  
  .menu-table td {
    padding: 10px;
    vertical-align: middle;
  }

  
  
  .heading-row {
    background-color: #e0e0e0;
    font-weight: bold;
    text-align: left;
    font-size: 13px;
  }
  
  .menu-item {
    cursor: pointer;
    position: relative;
    font-size: 13px;
  }

  .menu-item:hover{
    background-color: #f9f9f9;
  }
  
  .dropdown-arrow {
    font-size: 15px;
    margin-left: 5px;
  }
  
  .submenu-row td {
    padding: 0;
  }
  
  .submenu-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
  }
  
  .submenu-table th,
  .submenu-table td {
    padding: 8px;
    text-align: center;
    font-size: 13px;
  }
  
  .submenu-table th {
    background-color: #f9f9f9;
    font-weight: normal;
    font-size: 13px;
  }
  
  .submenu-table tr:not(:last-child) {
    border-bottom: 1px solid #ddd; /* Horizontal line between submenu rows */
  }


  /* select  style */
  
  /* VerticalMenu.css */


.menu-table {
  width: 100%;
  border-collapse: collapse;
}




.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0077D4;
}

input:checked + .slider:before {
  transform: translateX(14px);
}


/* Rounded Checkbox Styling */
input[type="checkbox"].rounded-checkbox {
  width: 18px;            /* Set the width of the checkbox */
  height: 18px;           /* Set the height of the checkbox */
  appearance: none;       /* Remove default checkbox styling */
  border: 2px solid #666666; /* Customize the border color */
  border-radius: 50%;     /* Make the checkbox rounded */
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Checkbox checked state */
input[type="checkbox"].rounded-checkbox:checked {
  background-color: #1045be; /* Color when checked */
  border: 1px solid #1045be;
}

input[type="checkbox"].rounded-checkbox:checked::after {
  content: '';
  display: block;
  width: 8px;             /* Checkmark size */
  height: 8px;
  margin: -4px;
  background-color: white; /* Checkmark color */
  border-radius: 50%;      /* Checkmark rounded */
}




  
  