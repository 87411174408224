body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Inter", sans-serif;
  background-color: #f1f5f9;
}
.body-no-scroll {
  overflow: hidden;
}
:root {
  --primary: #02a8b5;
  --secondary: #ffffff1f;
  --tertiary: #02a8b533;
  --fontPrimary: #323a46;
  --fontSecondary: #343a40;
  --fontTertiary: #93a4ad;
  --bgcolor: #f1f5f9;
  --bgcolorDark: #e5e9ed;
  --bgHoverPrimary: #f4f9fb;
  --btnHoverPrimary: #018e98;
  --redBtnPrimary: #cd485c;
  --redBtnHover: #f1556c;
  --redBtnGS: #bf1515;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #02a8b533;
}
.loading h1 {
  color: white;
}
.MuiInputLabel-asterisk {
  color: red !important; /* Use !important to ensure override */
}

/* ADD BELOW CSS START */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
/* ADD BELOW CSS END */

/* ADD BELOW MEDIA QUERY START */
/* Responsive styles */
@media (max-width: 768px) {
  .gsHeadingNavBoxMain nav ul {
      flex-direction: column;
      position: fixed;
      top: 70px;
      right: -100%;
      width: 200px;
      background-color: white;
      height: 100vh;
      transition: right 0.3s ease;
  }

  .gsHeadingNavBoxMain nav ul li {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      position: relative;
      margin-bottom: 10px;
  }

  .menu-toggle {
      display: block;
  }

  .gsHeadingNavBoxMain nav ul.active {
      right: 0;
      background-color: aliceblue;
      padding-top: 20px;
      padding-left: 10px;body {
        margin: 0;
        /* font-family: "Montserrat", sans-serif; */
        font-family: "Inter", sans-serif;
        background-color: #f1f5f9;
      }
      .body-no-scroll {
        overflow: hidden;
      }
      :root {
        --primary: #02a8b5;
        --secondary: #ffffff1f;
        --tertiary: #02a8b533;
        --fontPrimary: #323a46;
        --fontSecondary: #343a40;
        --fontTertiary: #93a4ad;
        --bgcolor: #f1f5f9;
        --bgcolorDark: #e5e9ed;
        --bgHoverPrimary: #f4f9fb;
        --btnHoverPrimary: #018e98;
        --redBtnPrimary: #cd485c;
        --redBtnHover: #f1556c;
        --redBtnGS: #bf1515;
      }
      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10rem;
        background-color: #02a8b533;
      }
      .loading h1 {
        color: white;
      }
      .MuiInputLabel-asterisk {
        color: red !important; /* Use !important to ensure override */
      }
      
      /* ADD BELOW CSS START */
      .menu-toggle {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }
      /* ADD BELOW CSS END */
      
      /* ADD BELOW MEDIA QUERY START */
      /* Responsive styles */
      @media (max-width: 768px) {
        .gsHeadingNavBoxMain nav ul {
            flex-direction: column;
            position: fixed;
            top: 70px;
            right: -100%;
            width: 200px;
            background-color: white;
            height: 100vh;
            transition: right 0.3s ease;
        }
      
        .gsHeadingNavBoxMain nav ul li {
            cursor: pointer;
            display: inline-block;
            font-size: 16px;
            position: relative;
            margin-bottom: 10px;
        }
      
        .menu-toggle {
            display: block;
        }
      
        .gsHeadingNavBoxMain nav ul.active {
            right: 0;
            background-color: aliceblue;
            padding-top: 20px;
            padding-left: 10px;
        }
      
        .gsHeadingLogoBox img {
            height: 50px;
        }
      
        .gsHeadingNavBoxMain nav {
            display: flex;
        }
      
        .gsHeadingNavBoxMain nav ul {
            display: flex;
            list-style: none;
            padding-inline: 30px;
            margin: 0;
        }
      }
      /* ADD BELOW MEDIA QUERY END */
      
      
      
      
      
      
  }

  .gsHeadingLogoBox img {
      height: 50px;
  }

  .gsHeadingNavBoxMain nav {
      display: flex;
  }

  .gsHeadingNavBoxMain nav ul {
      display: flex;
      list-style: none;
      padding-inline: 30px;
      margin: 0;
  }
}
/* ADD BELOW MEDIA QUERY END */





