.gsHeadingMainOuterBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  /* border: 2px solid red; */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.gsHeadingLogoBox {
  margin-left: 10px;
  cursor: pointer;
}
.gsHeadingLogoBox:hover {
  opacity: 0.8;
}
.gsHeadingNavBoxMain nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-inline: 30px;
}

.gsHeadingNavBoxMain nav li {
  margin-inline: 15px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: var(--fontSecondary);
}
.gsHeadingMainOuterBox:hover nav li {
  color: gray;
}
.gsHeadingNavBoxMain nav li::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  height: 2px;
  width: 0;
  background-color: dodgerblue;
  /* background-color: orange;  */
  transition: width 0.3s ease-out;
}

.gsHeadingNavBoxMain nav li:hover::after {
  width: 100%; /* Full width on hover */
}
.gsHeadingNavBoxMain nav li:hover {
  color: var(--redBtnGS);
  /* color: black; */
}