.errorModal {
    content: '';
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Set a fixed width for the modal */
    padding: 20px; /* Add padding */
    background: white; /* Background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
    z-index: 1000; /* Ensure modal is above other elements */
  }
  
  .errorModalOverlay {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Overlay above everything */
  }
  
  .errorModalButtons {
    display: flex;
    justify-content: space-between; /* Space buttons apart */
    margin-top: 20px; /* Space above buttons */
  }
  
  .modalButton {
    padding: 10px 15px; /* Button padding */
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .modalButton:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  